export class FixedBottomArea {
    private readonly BANNERS_SECTION_SELECTOR = "#fixed-bottom-area .banners";
    private readonly ONE_TRUST_CONTAINER_CLASS = ".optanon-alert-box-wrapper";

    public init() {
        this._setUpOneTrustObserver();
    }

    private _setUpOneTrustObserver() {
        const target = document.body;
        const fixedBottomAreaBannersContainer = document.querySelector(
            this.BANNERS_SECTION_SELECTOR
        );

        if (!fixedBottomAreaBannersContainer) {
            return;
        }

        const oneTrustObserver = new MutationObserver((mutations, observer) => {
            const oneTrustContainer = document.querySelector(this.ONE_TRUST_CONTAINER_CLASS);

            mutations.every(() => {
                if (oneTrustContainer) {
                    observer.disconnect();
                    fixedBottomAreaBannersContainer.appendChild(oneTrustContainer);
                }
            });
        });

        oneTrustObserver.observe(target, { childList: true });
    }
}
