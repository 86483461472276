import { FixedBottomArea } from "./FixedBottomArea";
import { BannersObserver } from "./NewOneTrust/BannersObserver";
import { OneTrustObserver } from "./NewOneTrust/OneTrustObserver";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { Container } from "typescript-ioc";

import "../Styles/FixedBottomArea.css";

const fixedBottomArea = new FixedBottomArea();
const oneTrustObserver = new OneTrustObserver();
const bannersObserver = new BannersObserver();

Container.get(ReadyHelper).bindDomReady(() => {
    bannersObserver.init();
    fixedBottomArea.init();
    oneTrustObserver.init();
});
