// Supports new OneTrust component, it pins to fixedBottomArea section when exists.
export class OneTrustObserver {
    private readonly FIXED_BOTTOM_AREA = "#fixed-bottom-area";
    private readonly ONE_TRUST_CONTAINER_ID = "#onetrust-consent-sdk";
    private readonly OBSERVER_CONFIG = { attributes: true, childList: true, subtree: true };

    public init() {
        this._setUpOneTrustObserver();
    }

    private _setUpOneTrustObserver() {
        const target = document.body;
        const fixedBottomAreaContainer = document.querySelector(this.FIXED_BOTTOM_AREA);

        if (!fixedBottomAreaContainer) {
            return;
        }

        const oneTrustObserver = new MutationObserver((mutations, observer) => {
            const oneTrustContainer = document.querySelector(this.ONE_TRUST_CONTAINER_ID);

            mutations.every(() => {
                if (oneTrustContainer) {
                    fixedBottomAreaContainer.appendChild(oneTrustContainer);
                    observer.disconnect();
                }
            });
        });

        oneTrustObserver.observe(target, this.OBSERVER_CONFIG);
    }
}
