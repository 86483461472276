// Supports new OneTrust component. When component exists, it recalculates the height of OneTrust to properly lift banners section
export class BannersObserver {
    private readonly BANNERS_SECTION_SELECTOR = "#fixed-bottom-area .banners";
    private readonly FIXED_BOTTOM_AREA = "#fixed-bottom-area";
    private readonly ONE_TRUST_BANNER_ID = "#onetrust-banner-sdk";
    private readonly OBSERVER_CONFIG = { attributes: true, childList: true, subtree: true };

    public init() {
        this._setUpBannersObserver();
    }

    private _setUpBannersObserver() {
        const fixedBottomArea = document.querySelector(this.FIXED_BOTTOM_AREA);
        if (!fixedBottomArea) {
            return;
        }

        const bannersObserver = new MutationObserver((mutations) => {
            mutations.every(() => {
                if (fixedBottomArea) {
                    const oneTrustBanner = document.querySelector(this.ONE_TRUST_BANNER_ID);
                    if (oneTrustBanner) {
                        const oneTrustBannerHeight = window.getComputedStyle(oneTrustBanner).height;
                        const bannersContainer = document.querySelector(
                            this.BANNERS_SECTION_SELECTOR
                        ) as HTMLElement;
                        if (bannersContainer) {
                            bannersContainer.style.marginBottom = oneTrustBannerHeight;
                        }
                    }
                }
            });
        });

        bannersObserver.observe(fixedBottomArea, this.OBSERVER_CONFIG);
    }
}
